import React from "react";
/* import {FaSearch} from 'react-icons/fa' */ //importing our react icons thus Fa(fontawesome)
import "./home.css";
import HHI from './HHI.jpg'/* 
import JS from './jsfree.png'
import CSS from './cssfree.png'
import HTML from './htmlfree.png'
import AI from './aifree.png' */





 
//the home header image
export class HeadImage extends React.Component {
    render(){
    return (
      <div className="HeadImageDiv">
        <img
          className="HeadImage"
          src={HHI} 
          alt="HeadImage" 
        />

<div className="course">
          <div className="course-content">
                <div className="content">

                <div className="course-box1">
                    <h3>Black Time Academy</h3>
                     <h2>HTML</h2>
                <p>  "HYPER TEXT MARKUP LANGUAGE" </p>
                    <br></br><br></br> <hr></hr>
                     <div  className="bta">
                      <h4>Free Course</h4>
                     <h4 className="h4">BTA</h4>
                     </div>
                  </div>
                    <h2>HTML TUTORIAL</h2>
                    <p>
                    Getting started with HTML? You are at the right place! Here is the complete HTML Tutorial PDF eBook
                </p>
                    <a href="/pdf">Enroll Now</a>
                </div><br></br>
                {/* end of html course */}

                <div className="content">

                <div className="course-box2">
                    <h3>Black Time Academy</h3>
                     <h2>CSS</h2>
                <p>  "CASCADING STYLE SHEET" </p>
                    <br></br><br></br> <hr></hr>
                     <div  className="bta">
                      <h4>Free Course</h4>
                     <h4 className="h4">BTA</h4>
                     </div>
                  </div>
                    <h2>CSS TUTORIAL</h2>
                    <p>
                    Getting started with CSS? You are at the right place! Here is the complete HTML Tutorial PDF eBook
                 </p>
                    <a href="/pdf">Enroll Now</a>
                </div><br></br>
                {/* END OF CSS */}  


                <div className="content">

                <div className="course-box3">
                    <h3>Black Time Academy</h3>
                     <h2>JS</h2>
                <p>  "JAVASCRIPT" </p>
                    <br></br><br></br> <hr></hr>
                     <div  className="bta">
                      <h4>Free Course</h4>
                     <h4 className="h4">BTA</h4>
                     </div>
                  </div>
                    <h2>JAVASCRIPT TUTORIAL</h2>
                    <p>
                    Getting started with JAVASCRIPT? You are at the right place! Here is the complete HTML Tutorial PDF eBook
              </p>
                    <a href="/pdf">Enroll Now</a>
                </div><br></br>
              {/* END OF JS */}
              <div className="content">

<div className="course-box4">
    <h3>Black Time Academy</h3>
     <br></br><br></br><h2>AI MARKETING</h2>
<p>  "DIGITAL MARKETING WITH AI" </p>
    <br></br><br></br><br></br> <hr></hr>
     <div  className="bta">
      <h4>Free Course</h4>
     <h4 className="h4">BTA</h4>
     </div>
  </div>
    <h2>MARKETING TUTORIAL</h2>
    <p>
    Getting started with MARKETING? You are at the right place! Here is the complete HTML Tutorial PDF eBook
    </p>
    <a href="/pdf">Enroll Now</a>
</div>
          </div>
     {/* end of course content 1 */}




    

    
  </div>


  </div>
 );
}
  }
  








