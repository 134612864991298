import React from "react";
import NavBar from "../navbar/navbar";
import pdf from './cover2.png';
import { useEffect,useState } from "react";

/* importing our css file
 */import './productdetail.css';
 
import HTML2 from './HTMLWIZARD2.jpg'
import CSS from './csshtmlcover.png'
import MARKETING from './digitalcover1.png'
import JS from './jscover1.png'




 export function ProductDetail(){
  const [data, setData] = useState([]);

  const fetchData = () => {
    fetch(`http://localhost:3000/Productdetail/book.js`)
      .then((response) => response.json())
      .then((actualData) => {
        console.log(actualData);
        setData(actualData.products);
        console.log(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);  



        return (
        <div>

        {/**navbar */}
          <NavBar/>


{/* product details */}
          <div className="productdetail">

            <div className="img-section">
                <h3>Sale!</h3>
                 <img  src={pdf}  className="productimg" alt="imgdetail"/>
          </div>

          <div className="detail-section">
                  <h2>HTML Tutorial PDF eBook - Black Time Academy</h2><br></br>
                <div className="price">
                    <strike>$10</strike><span>  $5</span>
                </div> <br></br>
                  <p>Getting started with HTML? You are at the right place!
                     Here is the complete HTML Tutorial PDF eBook<br></br> to help you Get started with the fondamentals of web development.
                    Thus HTML is the starting point<br></br> if you are willing to become a web developper 
                    because is from here that you will learn how to come out<br></br> with the Skeleton or Structure of a website.
                    this book is prepared by our experts.</p><br></br>

                  <a href="hhj">Download and Read the First Chapter for Free</a><br></br><br></br>
                  <button>CHECKOUT</button>
          </div>

        </div>

{/* book description section */}

<div  className="description">
  <h2>Description</h2>

            <p>Getting started with HTML? You are at the right place!
                     Here is the complete HTML Tutorial PDF eBook<br></br> to help you Get started with the fondamentals of web development.
                    Thus HTML is the starting point<br></br> if you are willing to become a web developper 
                    because is from here that you will learn how to come out<br></br> with the Skeleton or Structure of a website.
                    this book is prepared by our experts.</p>

</div>


{/* the chapters section */}

<div  className="description">

<h2>BOOK CONTENT</h2>
<p><span>Chapter1:</span>some text here!</p>
<p><span>Chapter2:</span>some text here!</p>
<p><span>Chapter3:</span>some text here!</p>
<p><span>Chapter4:</span>some text here!</p>
<p><span>Chapter5:</span>some text here!</p>
<p><span>Chapter6:</span>some text here!</p>
<p><span>Chapter7:</span>some text here!</p>
<p><span>Chapter8:</span>some text here!</p>
<p><span>Chapter9:</span>some text here!</p>
<p><span>Chapter10:</span>some text here!</p>

</div>




<div  className="products">
  <h4>Shop</h4>
  <div className="products-items">

 {/* ebook1 */}
       <div  className="ebook">

        <img src={HTML2} className="ebook-image" alt="ebook"/>
        <h5>COMPLETE HTML GUIDE<br></br> FOR BEGINNER</h5>
        <span>$5</span><br></br>
        <a href="/Productdetail"><button>View Details</button></a>

       </div>

       {/* ebook2 */}
       <div  className="ebook">

        <img src={CSS} className="ebook-image" alt="ebook"/>
        <h5>RESPONSIVE WEB <br></br>DESIGN WITH HTML CSS </h5>
        <span>$12</span><br></br>
        <a href="/Productdetail"><button>View Details</button></a>

       </div>

       {/* ebook3 */}
       <div  className="ebook">

        <img src={JS} className="ebook-image" alt="ebook"/>
        <h5>A-Z JAVASCRIPT FROM<br></br> BEGINNER TO ADVANCE</h5>
        <span>$15</span><br></br>
        <a href="/Productdetail"><button>View Details</button></a>

       </div>

       {/* ebook4 */}
       <div  className="ebook">

        <img src={MARKETING} className="ebook-image" alt="ebook"/>
        <h5>DIGITAL MARKETING<br></br> WITH AI</h5>
        <span>$15</span><br></br>
        <a href="/Productdetail"><button>View Details</button></a>

       </div>

       </div>
 </div>
 {/* end of product */}


       </div> 
       );
    
}


export default ProductDetail;