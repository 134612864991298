import React from "react";  //importing reactjs in my navbar
 import { FaBars, FaTimes, /* FaCartPlus,FaGlobe */} from 'react-icons/fa' //importing our react icons thus Fa(fontawesome)
 import { useRef } from "react"; //this module is use to add functionality to our navbar
import './navbar.css'  //importing css in my navbar
import BTA from '../BTA.png'
//import Register from "../register/Register";

//creating the navbar functional component and exporting

function  NavBar() {

   const navRef=useRef();

   const  showNavbar=()=>{
      navRef.current.classList.toggle("responsive_nav")
   }
   return( 
       <div className="main">

<div className="navbar-brand">
   <img src={BTA}  alt="bta"  height={60} /><span>Black Time Academy</span>
  </div>
  
   <nav  className="navbar" ref={navRef}>
  
   
  
 <ul className="navbar-menu">

{/**the header search 

<div className="navbar-search">
<input  type="text" placeholder="search course" />
<button><FaSearch/></button>

</div>*/}

  {/*  <li className="navbar-menu-item">
   <a href="/">Categories</a>
</li> */}


{/**   <ul className="navbar-menu">*/}
<li className="navbar-menu-item">
   <a href="/">Home</a>
</li>

<li className="navbar-menu-item">
   <a href="/Pdf">Download PDF</a>
</li>
<li className="navbar-menu-item">
   <a href="/Jobs">Jobs</a>
</li>
<li className="navbar-menu-item">
   <a href="/Investors">Investors</a>
</li>
<li className="navbar-menu-item">
   <a href="/Mentor">Become a mentor</a>
</li>
<li className="navbar-menu-item">
   <a href="/Mentor">Shop</a>
</li>

{/**<li className="navbar-menu-item">
<div className="cart"><FaCartPlus/></div>  this button is use to open the navigation on smaller screens
</li>*/}
{/* <li className="navbar-menu-item">
   <a href="/register"><button>sign up</button></a>
</li>
<li className="navbar-menu-item">
   <a href="/signin"><button>login</button></a>
</li> */}
{/* <li className="languages">

      <select >
      <FaGlobe/>
      <option>  English</option>
      <option> French</option>


      </select>

      
</li> */}


{/** </nav> </ul>*/}

<button className="nav-btn nav-close-btn" onClick={showNavbar}>
<FaTimes/>   {/**icons are like usual components that is inside this folder and we can therefor use them like this*/}
</button>

</ul>

</nav>

<button   className="nav-btn" onClick={showNavbar}>
<FaBars/>   {/**this button is use to open the navigation on smaller screens*/}
</button>


</div>
);
   




}

export default NavBar;




