import React from "react"; 
import NavBar from "../navbar/navbar";
/* import investor from './investor.jpg'
 */import './investors.css'



 export class InvestorsComp extends React.Component{
     
    render(){
        return<div>
<NavBar/>
<h1>SOON.....</h1>

{/* <img
          className="Investor"
          src={investor} 
          alt="HeadImage" 
        /> */}
        </div>
    }
}