import React from "react";  //importing reactjs in my navbar
import './footer.css'

export class FooterComp extends React.Component{

    render(){
        return  <div className="footer">
         <div className="upperDiv">
        {/* <div className="linksContainer"> */}
             {/* <div className="linksDiv linksDiv1">
              <p>Udemy for Business</p>
              <p>Teach on Udemy</p>
              <p>Get the app</p>
              <p>About us</p>
              <p>Contact us</p>
            </div>
            <div className="linksDiv linksDiv2">
              <p>Careers</p>
              <p>Blog</p>
              <p>Help and Support</p>
              <p>Affliate</p>
              <p> </p>
            </div>
            <div className="linksDiv linksDiv3">
              <p>Terms</p>
              <p>Privacy policy and cookie policy</p>
              <p>Sitemap</p>
              <p>Featured courses</p>
              <p> </p>
            </div>*/}
          </div>
{/*           <div className="linksDiv linksDiv4"></div>
     </div> */}       
          <div className="copyrightDiv">
            <p>© 2023 Black Time Academy, Inc.</p>
          </div>
        
      </div>
    }
}