import React from "react";
/* import React, { useState } from 'react';
 */
import { HeadImage } from '../home/home';
import { FooterComp } from "../footer/footer";
import NavBar from "../navbar/navbar";

export function PublicComp(){
   
    return(

    <div>
      
        <NavBar/>
        <HeadImage/>
        <FooterComp/>
        
     </div>
    )
}