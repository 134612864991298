import React from "react"; //importing react.
import './Mentor.css'; //importing my css
import NavBar from "../navbar/navbar";
/* import Mentor from './JO.jpg';
 */import './Mentor.css'


export class MentorComp extends React.Component{

render(){
    return  <div>
       <NavBar/>
       <h1>Section still under development Contact Us on whatsapp by clicking the number.! <a href="/">0900</a>.....</h1>

      {/*  <img
          className="Mentor"
          src={Mentor} 
          alt="HeadImage" 
        /> */}

    </div>
}

}

