import React from "react";
import NavBar from "../navbar/navbar";
import { FooterComp } from "../footer/footer";
import pdf from './pdf.jpg';/* 
import SOLOMON from './SOLOMON.PNG'
import CANDLE from './CANDLE.png'
import chia from './chia.png'
import label from './label.png'
import mern from './mern.png'
import nodejs from './nodejs.png'
import web from './web.png'
/* import AI from './AI.png' */
import HTML2 from './HTMLWIZARD2.jpg'
import CSS from './csshtmlcover.png'
import MARKETING from './digitalcover1.png'
import JS from './jscover1.png'

 import './pdf.css'; 


export class PdfComp extends React.Component{
    render(){

        return <div>
          {/**navbar */}
          <NavBar/>
                   
         {/**Pdf heading image */}

    <div className="pdf-heading">

<div className="pdfcontent">

<h2 className="pdf-head">Black Time Academy</h2>
              <h3  className="pdf-about">Learning is the ultimate tool for success</h3>
          <p className="pdf-about">
            Get unlimited access to 4,000+ of top courses for your team.
          </p>
          <div className="pdfbutton">Download Free PDF</div>

</div>

    <div className="pdfimg">
        <img
          src={pdf} width={400} height={270}
          alt="instructorImg"
          className="Img"
        ></img>
    </div>
       
 </div>


 <div  className="products">
  <h4>Shop</h4>
  <div className="products-items">

 {/* ebook1 */}
       <div  className="ebook">

        <img src={HTML2} className="ebook-image" alt="ebook"/>
        <h5>COMPLETE HTML GUIDE<br></br> FOR BEGINNER</h5>
        <span>$5</span><br></br>
        <a href="/Productdetail"><button>View Details</button></a>

       </div>

       {/* ebook2 */}
       <div  className="ebook">

        <img src={CSS} className="ebook-image" alt="ebook"/>
        <h5>RESPONSIVE WEB <br></br>DESIGN WITH HTML CSS </h5>
        <span>$12</span><br></br>
        <a href="/Productdetail"><button>View Details</button></a>

       </div>

       {/* ebook3 */}
       <div  className="ebook">

        <img src={JS} className="ebook-image" alt="ebook"/>
        <h5>A-Z JAVASCRIPT FROM<br></br> BEGINNER TO ADVANCE</h5>
        <span>$15</span><br></br>
        <a href="/Productdetail"><button>View Details</button></a>

       </div>

       {/* ebook4 */}
       <div  className="ebook">

        <img src={MARKETING} className="ebook-image" alt="ebook"/>
        <h5>DIGITAL MARKETING<br></br> WITH AI</h5>
        <span>$15</span><br></br>
        <a href="/Productdetail"><button>View Details</button></a>

       </div>

       </div>
 </div>
 {/* end of product */}

 <FooterComp/>
      
   </div>


    }
  }