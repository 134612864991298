import React from "react";
import NavBar from "../navbar/navbar";
/* import job from './find-a-job.jpg'
 */import './job.css'


export class JobsComp extends React.Component{
    render(){

        return <div>
       <NavBar/>

    <h1>SOON.....</h1>

      {/*  <img
          className="job"
          src={job} 
          alt="HeadImage" 
        /> */}
        </div>

    }
}