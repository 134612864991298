import {Routes,Route} from 'react-router-dom';  
import { PublicComp } from './component/Public/Public'; 
import { MentorComp } from './component/becomementor/Mentor';
import { InvestorsComp } from './component/findinvestors/investors';
import { JobsComp } from './component/jobs/jobs';
import { PdfComp } from './component/pdf/pdf';
import { ProductDetail } from './component/productdetail/productdetail';




function App() {
  return (
  
   

    <div >

       <div>
       <Routes>
         
        <Route index element={<PublicComp/>}/>
          <Route  path='Mentor'  element={<MentorComp/>}/>
          <Route path='Investors' element={<InvestorsComp/>}/>
          <Route path='Jobs'  element={<JobsComp/>} />
          <Route path='Pdf' element={<PdfComp/>} />
          <Route path='Productdetail' element={<ProductDetail/>} />

        </Routes>
       </div>

      <div>
       {/** 
        *         <CenterMode/>  \

        {/** <BecomeInstructor/>*/}

       {/** <Recommendations/>
        <Feature2/>
        <FillerDiv/>
        <TopCategories/>
        <TrustedCompanies/>
         <VideoAdDiv/>

        
        {/** <HeaderimgComp />*/}
        {/** <LandingComp /> */}
        </div>

<div className="App">
    </div>

   {/** <FooterComp  />*/}

    </div>
  );
}

export default App;
